import * as Stats from 'stats.js';
import GUI from 'lil-gui';

import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import frag from './shaders/sphericalglow.frag';
import vert from './shaders/sphericalglow.vert';

class SphericalGlow {

    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;
    renderer: THREE.WebGLRenderer;
    controls: OrbitControls;
    composer: EffectComposer;

    bloomPass: UnrealBloomPass;

    clock: THREE.Clock = new THREE.Clock();
    stats: Stats = new Stats();

    duration: number = 30.0;
    startTime: number = this.clock.getElapsedTime();
    endTime: number = 0;

    uniforms;

    params = {
        exposure: 1,
        bloomThreshold: .0,
        bloomStrength: .75,
        bloomRadius: 1,
        m0: 0,
        m1: 1,
        m2: 0,
        m3: 1,
        m4: 0,
        m5: 1,
        m6: 0,
        m7: 1,
        weight: 1
    };

    target = {
        m0: 0,
        m1: 1,
        m2: 0,
        m3: 1,
        m4: 0,
        m5: 1,
        m6: 0,
        m7: 1,
    }

    constructor() {

        // three.js setup.
        {
            this.scene = new THREE.Scene();

            this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
            this.camera.position.z = 7.5;

            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.renderer.toneMapping = THREE.ReinhardToneMapping;

            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI / 2;
            this.controls.enableDamping = true;
            this.controls.dampingFactor = 0.05;
            this.controls.enablePan = false;

            //this.renderer.setPixelRatio(window.devicePixelRatio);
            document.body.appendChild(this.renderer.domElement);
        }

        // Postprocessing setup.
        {
            const renderScene = new RenderPass(this.scene, this.camera);

            this.bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
            this.bloomPass.threshold = this.params.bloomThreshold;
            this.bloomPass.strength = this.params.bloomStrength;
            this.bloomPass.radius = this.params.bloomRadius;

            const fxaaPass = new ShaderPass(FXAAShader);
            const pixelRatio = this.renderer.getPixelRatio();

            fxaaPass.material!.uniforms['resolution'].value.x = 1 / (window.innerWidth * pixelRatio);
            fxaaPass.material!.uniforms['resolution'].value.y = 1 / (window.innerHeight * pixelRatio);

            this.composer = new EffectComposer(this.renderer);
            this.composer.addPass(renderScene);
            this.composer.addPass(this.bloomPass);
            this.composer.addPass(fxaaPass);
        }

        // mesh setup.
        {
            // const texture = new THREE.TextureLoader().load('uvs.jpg');

            this.uniforms = {
                time: { type: "f", value: 1.0 },
                'm0': { value: this.params.m0 },
                'm1': { value: this.params.m1 },
                'm2': { value: this.params.m2 },
                'm3': { value: this.params.m3 },
                'm4': { value: this.params.m4 },
                'm5': { value: this.params.m5 },
                'm6': { value: this.params.m6 },
                'm7': { value: this.params.m7 },
                'weight': { value: this.params.weight },
                //map: { type: 't', value: texture }
            };

            const shaderMaterial = new THREE.ShaderMaterial({
                uniforms: this.uniforms,
                vertexShader: vert,
                fragmentShader: frag,
                blending: THREE.AdditiveBlending,
                depthTest: false,
                transparent: true,
                side: THREE.DoubleSide
            });

            const resolution = 256;

            //const geometry = new THREE.SphereGeometry(1, resolution, resolution / 2);
            //const geometry = new THREE.IcosahedronGeometry(1, 64);
            const geometry = new THREE.PlaneGeometry(4, 4, resolution, resolution / 2);
            const mesh = new THREE.Mesh(geometry, shaderMaterial);

            this.scene.add(mesh);
        }

        // Interaction.
        {
            let drag = false;

            this.renderer.domElement.addEventListener('mousedown', () => drag = false);
            this.renderer.domElement.addEventListener('mousemove', () => drag = true);
            this.renderer.domElement.addEventListener("click", (e) => {
                if (!drag) this.randomize();
            })

            window.addEventListener('resize', () => {
                this.camera.aspect = window.innerWidth / window.innerHeight;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(window.innerWidth, window.innerHeight);
                this.composer.setSize(window.innerWidth, window.innerHeight);
            }, false);
        }

        // stats setup.
        {
            this.stats.showPanel(0);
            document.body.appendChild(this.stats.dom);
            this.stats.dom.style.display = "none";
        }

        // lil gui setup.
        {
            const gui = new GUI();

            gui.add(this.params, 'exposure', 0.1, 2).onChange((value) => {
                this.renderer.toneMappingExposure = Math.pow(value, 4.0);
            });

            gui.add(this.params, 'bloomThreshold', 0.0, 1.0).onChange((value) => {
                this.bloomPass.threshold = Number(value);
            });

            gui.add(this.params, 'bloomStrength', 0.0, 3.0).onChange((value) => {
                this.bloomPass.strength = Number(value);
            });

            gui.add(this.params, 'bloomRadius', 0.0, 10.0).step(0.01).onChange((value) => {
                this.bloomPass.radius = Number(value);
            });

            gui.add(this.params, 'm0', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm1', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm2', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm3', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm4', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm5', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm6', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'm7', 0.0, 10.0).step(0.01);
            gui.add(this.params, 'weight', 0.0, 1.0).step(0.01);

            gui.hide();
        }

        this.animate();
    }

    randomize() {
        this.params["m0"] = (Math.random() * 6);
        this.params["m1"] = (Math.random() * 5) + 1;
        this.params["m2"] = (Math.random() * 6);
        this.params["m3"] = (Math.random() * 5) + 1;
        this.params["m4"] = Math.floor(Math.random() * 6);
        this.params["m5"] = (Math.random() * 5) + 1;
        this.params["m6"] = Math.floor(Math.random() * 6);
        this.params["m7"] = (Math.random() * 5) + 1;

        this.target["m0"] = (Math.random() * 6);
        this.target["m1"] = (Math.random() * 5) + 1;
        this.target["m2"] = (Math.random() * 6);
        this.target["m3"] = (Math.random() * 5) + 1;
        this.target["m4"] = this.params["m4"];
        this.target["m5"] = (Math.random() * 5) + 1;
        this.target["m6"] = this.params["m6"];
        this.target["m7"] = (Math.random() * 5) + 1;
    }

    animate() {
        let delta = this.clock.getDelta();

        this.stats.begin();

        let progress = (this.clock.getElapsedTime() - this.startTime) / (this.endTime - this.startTime);

        this.uniforms["time"].value = this.clock.getElapsedTime();

        this.uniforms["m0"].value = THREE.MathUtils.lerp(this.params.m0, this.target.m0, progress);
        this.uniforms["m1"].value = THREE.MathUtils.lerp(this.params.m1, this.target.m1, progress);
        this.uniforms["m2"].value = THREE.MathUtils.lerp(this.params.m2, this.target.m2, progress);
        this.uniforms["m3"].value = THREE.MathUtils.lerp(this.params.m3, this.target.m3, progress);
        this.uniforms["m4"].value = THREE.MathUtils.lerp(this.params.m4, this.target.m4, progress);
        this.uniforms["m5"].value = THREE.MathUtils.lerp(this.params.m5, this.target.m5, progress);
        this.uniforms["m6"].value = THREE.MathUtils.lerp(this.params.m6, this.target.m6, progress);
        this.uniforms["m7"].value = THREE.MathUtils.lerp(this.params.m7, this.target.m7, progress);

        if (progress <= .1) {
            this.params["weight"] = this.easeInOutCubic(progress * 10);
        } else if (progress >= .9) {
            this.params["weight"] = this.easeInOutCubic((1 - progress) * 10);
        } else {
            this.params["weight"] = 1.0;
        }

        if (progress >= 1) {
            this.startTime = this.clock.getElapsedTime();
            this.endTime = this.startTime + this.duration;
            this.randomize();
        }

        this.uniforms["weight"].value = this.params.weight;

        //renderer.render(scene, camera);
        this.controls.update();
        this.composer.render();

        this.stats.end();

        this.updateTime();

        requestAnimationFrame(this.animate.bind(this));
    };

    updateTime() {
        let info = document.getElementById("info");
        let date = new Date();
        let timeString = date.toLocaleTimeString('en-US', { hour12: false })
        info!.innerText = timeString;
    }

    easeInOutCubic(x) {
        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    }

}

export default new SphericalGlow();