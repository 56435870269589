precision highp float;

#define PI 3.141592
#define TWO_PI PI * 2.

uniform float time;

uniform float m0;
uniform float m1;
uniform float m2;
uniform float m3;
uniform float m4;
uniform float m5;
uniform float m6;
uniform float m7;

uniform float weight;

varying vec4 color;
varying float fresnel;

vec3 evaluate(vec2 uv, float time) {
    float r, phi, theta;
    float x, y, z;

    theta = (1. - uv.x) * TWO_PI;
    phi = (1. - uv.y) * PI;

    r = 0.0;
    r += pow(abs(sin(m0 * phi)), m1);
    r += pow(abs(cos(m2 * phi)), m3);
    r += pow(abs(sin(m4 * theta)), m5);
    r += pow(abs(cos(m6 * theta)), m7);

    r = mix(2., r, weight);

    theta = mod(theta - (time / 2.5), TWO_PI);

    x = r * sin(phi) * cos(theta);
    z = r * sin(phi) * sin(theta);
    y = r * cos(phi);

    return vec3(x, y, z);
}

void main() {

    vec3 currentPosition = evaluate(uv, time);
    vec3 northPosition = evaluate(vec2(uv.x, uv.y + .01), time);
    vec3 eastPosition = evaluate(vec2(uv.x + .005, uv.y), time);

    vec4 newPosition = vec4(currentPosition, 1.0);

    vec4 worldPosition = modelMatrix * newPosition;
    vec3 toEye = cameraPosition - newPosition.xyz;

    fresnel = dot(normalize(toEye), normalize(worldPosition.xyz));
            //fresnel = fresnel * fresnel;

    color = mix(vec4(0.0, 1.0, 0.5, 1.0) * .5, vec4(0.0, 0.5, 1.0, 1.0) * .5, 1.0 - length(newPosition.xyz) / 3.0);
    color -= (1.0 - fresnel) * .2;
    color.a = 1.0;

    vec3 normal = normalize(cross(normalize(northPosition - currentPosition), normalize(eastPosition - currentPosition)));
            //color = vec4((normal.xyz + 1.)/2.,1.);

    fresnel = dot(normalize(toEye), normalize(normal));
            //fresnel = (fresnel + 1.)/2.;
            //fresnel = 1.-(fresnel * fresnel);

            //color = vec4(fresnel, fresnel, fresnel, 1.);
    color.a = (1. - (fresnel * fresnel));

    vec4 outPosition = projectionMatrix * modelViewMatrix * newPosition;
    gl_Position = outPosition;
}
